.contact-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 20px;
    max-width: 600px;
    margin: 0 auto;
  }
  
  .contact-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .contact-input {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
  }
  
  .contact-textarea {
    width: 100%;
    padding: 8px;
    margin: 5px 0;
    min-height: 100px;
  }
  
  .contact-button {
    padding: 10px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    background-color: rgba(0, 0, 0, 0.5);
}
  
  .modal-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 400px;
    text-align: center;
  }

  .close {
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 20px;
    color: #000;
    cursor: pointer;
  }
  
  .close:hover {
    cursor: pointer; 
  }
  