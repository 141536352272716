body {
    background-color: #f0f8ff;
  }
  
  .our-story-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width: 1000px;
    padding: 40px 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .our-story-title {
    color: #005f99;
    text-align: center;
    font-size: 36px;
    margin-bottom: 20px;
  }
  
  .our-story-content h2 {
    color: #005f99;
    font-size: 28px;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .our-story-content p, .our-story-content ul {
    color: #333333;
    font-size: 18px;
    line-height: 1.6;
  }
  
  .our-story-content ul {
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .our-story-content li {
    margin-bottom: 10px;
  }
  
  .cta-button {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #007acc;
    width: 200px;
    color: #ffffff;
    padding: 12px 24px;
    font-size: 18px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 20px;
    /* margin: 0 auto 0 auto; */
  }
  
  .cta-button:hover {
    background-color: #005f99;
  }
  