/* General Styles */
body {
  font-family: 'Poppins', sans-serif;
}

/* Container */
.landing-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 150px;
  text-align: left;
  font-size: 24.57px;
  color: #4a4a4a;
}

/* Header Section */
.header-section {
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding-left: 60px;
  gap: 50px;
  position: relative;
}


.header-content {
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-top: 15px;
}

.header-title {
  margin-top: 20px;
  font-size: 35px;
  font-weight: 600;
  color: #2a2a2a;
}

.header-description {
  font-size: 15px;
  font-weight: 400;
  color: #4a4a4a;
  line-height: 1.6;
  max-width: 800px;
}

.header-image {
  width: 72%;
}

/* Smooth scroll button */
.scroll-button {
  background: transparent;
  text-decoration: underline;
  color: #007bff;
}

#scrollTarget {
  padding-top: 150px; /* Adds space above the target */
  margin-top: -150px; /* Prevents visible shift after scroll */
}

/* Portfolio Cards */
.card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  gap: 32px;
}


