body {
  font-family: Arial, sans-serif;
  background-color: #f5f5f5;
}

.file-list-container {
  text-align: center;
}

.file-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  padding: 20px;
  margin: 0 auto;
  max-width: 1200px;
}

.file-item {
  position: relative;
  text-align: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s;
}

.file-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.file-item:hover {
  transform: scale(1.05);
}

.file-item img {
  max-width: 80px;
  max-height: 80px;
}

.file-name {
  margin-top: 10px;
  color: #171717;
  font-size: 14px;
  font-weight: 600;
}

.file-time {
  color: #8c8c8c;
  font-size: 12px;
  text-align: left;
  padding: 2px 4px;
  border-radius: 4px;
  margin-top: 5px;
}

.download-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.folder-grid {
  display: flex;
}

.breadcrumb-item {
  color: #000000;
  cursor: pointer;
}

.breadcrumb-item.inactive {
  color: #888888;
}

.breadcrumb-item.active {
  color: #000000;
}

.breadcrumb-arrow {
  margin: 0 8px;
  color: #cccccc;
}
