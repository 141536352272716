.navbar-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #f3f3f3;
  padding: 10px 0;
  z-index: 1000;
}

.navbar-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.navbar-logo {
  height: 80px;
}

.navbar-link {
  font-weight: 500;
  color: #6b7280;
  text-decoration: none;
  font-size: 16px;
}

.navbar-link:hover {
  color: #3b82f6;
}

.dropdown {
  position: relative;
  display: inline-block;
  
}

.dropdown-title {
  font-weight: 500;
  color: #6b7280;
  background: none;
  border: none;
  font-size: 16px;
}

.dropdown-title:hover {
  color: #3b82f6;
}

.dropdown-menu {
  display: none;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  background-color: #f3f3f3;
  padding: 10px 0;
  width: 200px;
  z-index: 100;
  text-align: center;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.dropdown-item {
  display: block;
  padding: 10px 0;
  color: #4b5563;
  text-decoration: none;
}

.dropdown-item:hover {
  background-color: #f3f3f3;
  color: #3b82f6;
}

.logout-button, .login-button {
  background-color: #d0cfcf;
  color: #4b5563;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  border: none;
}

.logout-button:hover, .login-button:hover {
  background-color: #a29e9e;
}

.navbar-user-info {
  display: flex;
  align-items: center;
}

.navbar-user-info a {
  text-decoration: none; /* Remove underline */
}


.user-initials {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #d0cfcf;
  color: #4b5563;
  font-weight: 500;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-right: 30px;
  margin-left: -30px;
}
