.stock-market-container {
    padding: 20px;
    margin: 0 50px;
  }
  
  .stock-market-container h2 {
    margin-bottom: 20px;
    font-size: 24px;
    text-align: center;
  }
  
  .stock-market-container p {
    margin-bottom: 15px;
    line-height: 1.6;
    font-size: 16px;
  }
  
  .stock-market-container select {
    padding: 8px;
    font-size: 14px;
    margin-bottom: 20px;
    width: 200px;
  }
  
  .stockchart-container {
    margin-top: 30px;
    max-width: 600px; 
    max-height: 300px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .stockchart-container canvas {
    width: 100% !important;
    height: 100% !important;
  }
  